@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/labs/lib/css/blueprint-labs.css";
@import "~normalize.css/normalize.css";
.container {
  display: flex;
  /* or inline-flex */
  flex-direction: row;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.app-toolbar-icon {
  padding-left: 10px;
  margin-left: 5px;
}

.App {
  width: 100%;
}

.content {
  width: 100%;
}

.event-table {
  table-layout: fixed;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 20px;
  overflow-x: auto;
}

.app-icon-button {
  margin-left: 5px;
}

.event-content {
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 50px;
}

.bp3-navbar-heading a {
  color: white;
}

.bp3-navbar-heading a:hover {
  color: white;
}

.newevent-content {
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 50px;
}

.new-event-create-buttons {
  padding-top: 20px;
}

.new-event-group-row {
  padding-top: 10px;
}

.event-enroll-button {
  padding-top: 30px;
}

.pt-input {
  margin-left: 5px;
}

.event-enroll-total {
  padding-top: 10px;
}

.login-prompt {
  text-align: center;
}

.login-errormessage {
  color: red;
  padding-top: 10px;
}

.input-w label,
.input-w input {
  float: none;
  /* if you had floats before? otherwise inline-block will behave differently */
  display: table-cell;
  vertical-align: middle;
  margin-bottom: 5px;
}

.event-info-search {
  display: inline-block;
}

.event-info-search-container {
  margin-left: 60px;
}

table.pt-html-table th,
table.pt-html-table td {
  vertical-align: middle;
}

.hide_in_desktop {
  display: none;
}

.paid-icon {
  margin-top: 6px;
  margin-left: 10px;
}

.participant-table-header {
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 2%;
}

.group-selector {
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 2%;
}

.groups-list {
  padding-right: 8px;
}

@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Force table to not be like tables anymore */
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }
  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  tr {
    border: 1px solid #ccc;
  }
  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }
  td:before {
    /* Now like a table header */
    /*position: absolute;*/
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }
  .hide_in_desktop {
    display: inline;
  }
  /*
	Label the data
	
	td:nth-of-type(1):before { content: "Tapahtuma: "; }
	td:nth-of-type(2):before { content: "Aika: "; }
	td:nth-of-type(3):before { content: "Ilmoittautuminen alkaa: "; }
	td:nth-of-type(4):before { content: "Ilmoittautuminen loppuu: "; }
	td:nth-of-type(5):before { content: "Paikka: "; }*/
}
